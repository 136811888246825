export default {
  data() {
    return {
      loading: false, // 是否加载中
      refreshing: false, // 是否刷新中
      finished: false, // 是否加载完成
      page: 0, // 页码
      limit: 10, // 条数
      total: 0, // 总数
      list: [], // 数据源
    }
  },
  created() {},
  methods: {
    /**
     * 初始化分页的数据
     * @param {number} limit 自定义页码 默认 10
     */
    _initPageData(limit = 10) {
      this.refreshing = false
      this.loading = false
      this.finished = false
      this.page = 0
      this.total = 0
      this.limit = limit
      this.list = []
    },
    // 请求处理中心
    async _getListFactory() {
      // 如果已经全部加载完成直接终止执行
      if (this.finished) return
      this.loading = true
      this.page++
       // 防止加载错误后一直显示加载中...
      try {
        await this._getList()
      } catch (error) {
        console.error(error)
      }
      this.loading = false
      // 判断是否全部加载完成
      if (this.list.length >= this.total) {
        this.finished = true
      }
    },
    /**
     * 上拉加载
     */
    async _onLoad() {
      await this._getListFactory()
    },
    /**
     * 下拉刷新
     */
    async _onRefresh() {
      // 初始化数据
      this._initPageData()
      this.refreshing = true
      await this._getListFactory()
      this.refreshing = false
    },
    async _getList() {
      // requset data ....
    },
  },
}
