<template>
  <div class="common-bg">
    <van-nav-bar title="转账记录" left-arrow @click-left="onClickLeft" />
    <van-list
      v-model:loading="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="_onLoad"
      v-if="list.length > 0"
    >
      <div v-for="item in list" :key="item.id">
        <div class="stock-card">
          <div class="card-li" v-if="item.moneyType === '转让'">
            <span class="text-grey">币种名称：</span>
            <span class="font-weight">{{ item.note }}</span>
          </div>
          <div class="card-li">
            <span class="text-grey">互转类型：</span>
            <span class="font-weight">{{ item.moneyType }}</span>
          </div>
          <div class="card-li">
            <span class="text-grey">互转账号：</span>
            <span class="font-weight">{{ item.toMobile }}</span>
          </div>
          <div class="card-li">
            <span class="text-grey">
              {{ item.moneyType === '转让' ? '互转数量：' : '互转金额：' }}
            </span>
            <span class="font-weight text-warning">{{ item.money }}</span>
          </div>
          <div class="card-li">
            <span class="text-grey">购买时间：</span>
            <span class="font-weight">{{ item.createTime }}</span>
          </div>
        </div>
      </div>
    </van-list>
    <div class="text-center padded-t-15" v-else>暂无记录</div>
  </div>
</template>

<script>
import pageMixin from '@/utils/mixins/page'
import { getIncome } from '@/utils/api.js'
export default {
  mixins: [pageMixin],
  data() {
    return {
      list: []
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      const form = {
        page: this.page,
        limit: this.limit,
        moneyType: 6
      }
      getIncome(form).then((res) => {
        this.total = res.data.totalCount
        this.list = res.data.list
      })
    },
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.stock-card {
  padding: 15px;
  background: hsla(0, 0%, 100%, 0.6);
  border-radius: 8px;
  font-size: 14px;
  margin: 10px;
  .card-li {
    padding: 3px 0;
  }
}
</style>
